import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class SelectPrimaryUserController extends Controller {
  connect() {
    this.element.addEventListener('change', (event) => {
      const selectedIndex = event.target.selectedIndex;
      const selectedOption = event.target.options[selectedIndex];

      axios
        .post(`/secondary_users/set_current_primary?primary_id=${selectedOption.value}`)
        .then(() => window.location.reload());
    });
  }
}
